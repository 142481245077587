<template>
    <div class="head-view">
        <img src="../assets/img/head.png" class="head-background">
        <!-- <img src="../assets/img/head-home.png" class="head-background" v-else> -->
        <!-- <img src="../assets/img/red60.png" class="blue"> -->
        <div class="school-logo">
          <img src="../assets/img/logo.png" class="logo">
          <img src="../assets/img/school.png" class="school">
        </div>
        <img :src="bannerUrl" class="header" v-if="$route.name == 'home'">
    </div>
  </template>
  <script>
  
  import { listSysBanner } from "@/api/system/sysBanner"
  export default {
    components: {},
    data(){
      return {
        bannerUrl: require('../assets/img/header.png')
      }
    },
    created() {
      this.getHomeBanner()
    },
    methods: {
    getHomeBanner(){
      listSysBanner().then(e =>{
        const url = e.rows[0].url
        this.bannerUrl =  url ? `${process.env.VUE_APP_BASE_API}${url}` : require('../assets/img/header.png')
      })
    },
      
    }
  }
  </script>
  <style scoped lang="less">
  .head-view{
    width: 100vw;
    position: relative;
  }
  .head-background{
    width: 100%;
    position: relative;
    z-index: 2;
    
  }
  .school-logo{
    display: flex;
    align-items: center;
    position: absolute;
    width: 90vw;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: space-between;
    z-index: 5;
  }
  .logo{
    width: 12vw;
    height: 12vw;
    // transform: translate(14px, -1px);
  }
  .school{
    width: 78vw;
  }
  .blue{
    width: 13%;
    position: absolute;
    top: 4px;
    right: 6px;
    z-index: 3;
  }
  
  .header{
    width: 100%;
    position: relative;
    z-index: 1;
    left: 0;
    transform: translateY(-42%);
  }
  </style>
  