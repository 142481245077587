import request from '@/utils/request'

// 查询sysBanner列表
export function listSysBanner(query) {
  return request({
    url: '/system/sysBanner/list',
    method: 'get',
    params: query
  })
}

// 查询sysBanner详细
export function getSysBanner(id) {
  return request({
    url: '/system/sysBanner/' + id,
    method: 'get'
  })
}

// 新增sysBanner
export function addSysBanner(data) {
  return request({
    url: '/system/sysBanner',
    method: 'post',
    data: data
  })
}

// 修改sysBanner
export function updateSysBanner(data) {
  return request({
    url: '/system/sysBanner',
    method: 'put',
    data: data
  })
}

// 删除sysBanner
export function delSysBanner(id) {
  return request({
    url: '/system/sysBanner/' + id,
    method: 'delete'
  })
}
