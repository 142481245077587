<template>
  <div :class="isMobile() ? 'home' : 'homeSmiple'" >
    <div class="action">
      <div :class="isMobile() ? 'action-list' : 'actionListSmiple'" v-for="item in activeList" :key="item.id" @click="goto(item)">
        <img :src="item.imgurl">
        <div>{{ item.name }}</div>
      </div>
    </div>
    <div :class="isMobile() ? 'menu' : 'menuSmiple'" >
      <div class="menu-item" v-for="(info, index) in menuList" :key="index">
        <div class="item-left">
          <img src="../assets/img/bg-1.png" v-if="index%2 !== 0">
          <img src="../assets/img/bg-2.png" v-else>
          <img :src="`${urlHead}${info.image}`">
        </div>
        <div class="item-right">
          <div class="action-name">{{ info.name }}</div>
          <div class="action-msg">活动时间：{{ info.hdsj }}</div>
          <div class="action-msg">报名人数：{{ info.bmnum }}/{{ info.maxbmnum }}&nbsp;录取人数：{{info.lqnum}}/{{ info.maxlqnum }}</div>
          <div class="action-msg text-msg" v-html="info.text"></div>
          <div class="detail-button"  @click="gotoDetail(info)" :style="{background: $mainColor}">查看详情
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listActivity } from "@/api/system/activity"
import { getToken } from '@/utils/auth'
export default {
  name: 'HomeView',
  components: {
  },
  data(){
    return {
      bannerUrl: '',
      activeList: [{
        imgurl: require('../assets/icon/icon-2.png'),
        name: '已预约',
        path: 'yyuyue',
        id: 1
      },
      {
        imgurl: require('../assets/icon/icon-3.png'),
        name: '录取通知书',
        path: 'luqu',
        id: 2
      }],
      queryParams: {
        username: '',
        state: 1
      },
      menuList: [],
      urlHead: process.env.VUE_APP_BASE_API
    }
  },
  computed: {
    isLogin() {
      return getToken()
    }
  },
  mounted() {
    if(this.isLogin) {
      this.$store.dispatch('GetInfo').then(()=>{
        this.getAction()
      })
    }else {
      this.getAction()
    }
  },
  methods: {
    getAction(){
        const userName = this.$store.state.user.name
        this.queryParams.username = userName;
        listActivity(this.queryParams).then(res =>{
          this.menuList = res.rows
        })
    },
    goto(row){
      this.$router.push(row.path)
    },
    gotoDetail(row){
      this.$router.push({
        path: '/detail',
        query:{
          id:row.id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.homeSmiple{
  width: 1200px !important;
  margin: 90px auto 0;
}
.home, .homeSmiple{
  width: 100%;
  .head{
    width: 100%;
    position: relative;
    img{
      width: 100%;
    }
    .school-logo{
      position: relative;
      z-index: 3;
    }
    .header{
      position: relative;
      z-index: 2;
      left: 0;
      transform: translateY(-42%);
    }
  }
  .action{
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    margin: -4.1rem 20vw 1.5rem;
    .actionListSmiple{
      margin: 20px 0;
      text-align: center;
      width: 300px;
      img{
        width: 100px;
        border-radius: 50%;
      }
      div{
        margin-top: 10px;
        font-size: 18px;
      }
    }
    .action-list{
      text-align: center;
      width: 5rem;
      img{
        width: 3rem;
        border-radius: 50%;
      }
      div{
        font-size: 0.8rem;
      }
    }
  }
  }
  .menuSmiple{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
    margin: 1rem auto;
    padding-bottom: 4rem;
    .menu-item{
      width: 45%;
      display: flex;
      position: relative;
      margin-bottom: 1rem;
      .item-left{
        img:first-child{
          width: 200px;
        }
        img:last-child{
          top: 19px;
          width: 109px;
          position: absolute;
          left: 10px;
        }
      }
      .item-right{
        margin-left: 10px;
        .action-name{
          font-size: 18px;
        }
        .action-msg{
          margin-top: 5px;
          font-size: 14px;
          overflow:hidden;
          text-overflow: ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
        }
        .detail-button{
          position: absolute;
          bottom: 3px;
          right: 0;
          color: #fff;
          padding: 5px 10px;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }

  }
  .text-msg{
    line-height: 13px;
    height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /deep/ img{
      display: none;
    }
    /deep/ br{
      display: none;
    }
    /deep/ p{
      // display: inline-block;
    }
  }
  .menu{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 90%;
    margin: 1rem auto;
    padding-bottom: 4rem;
    .menu-item{
      display: flex;
      position: relative;
      margin-bottom: 1rem;
      .item-left{
        img:first-child{
          width: 150px;
        }
        img:last-child{
          top: 19px;
          width: 75px;
          position: absolute;
          left: 10px;
        }
      }
      .item-right{
        margin-left: 5px;
        .action-name{
          font-size: 1rem;
        }
        .action-msg{
          margin-top: 1px;
          font-size: 0.6rem;
        }
        .detail-button{
          position: absolute;
          bottom: 3px;
          right: 0;
          color: #fff;
          padding: 3px 7px;
          border-radius: 5px;
          font-size: .8rem;
        }
      }
    }
  }
  
</style>
