import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import moment from 'moment'
import 'vant/lib/index.css';

Vue.prototype.$mainColor = '#3ea7d8'
Vue.prototype.$moment = moment
moment.locale('zh-cn')
Vue.use(Vant)

Vue.config.productionTip = false
Vue.prototype.isMobile = function() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
