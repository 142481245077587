<template>
  <div id="app">
    <headView v-if="isMobile()" />
    <headSmipleView v-if="!isMobile()"/>
    <router-view v-if="isRouterAlive"></router-view>
    <van-tabbar v-model="active" @change="onChange" v-if="isMobile() && showMainMenu" :active-color="$mainColor">
      <van-tabbar-item name="home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item name="my" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import headView  from './components/head.vue'
import headSmipleView  from './components/headSmiple.vue'

export default  {
  name:  'App',
  components: { headView, headSmipleView },
  provide (){
     return {
       reload:this.reload
     }
  },
  data(){
    return {
       active: 'home',
       isRouterAlive:true
    }
  },
  computed: {
    showMainMenu() {
      if (this.$route.name === 'login' || this.$route.name === 'yuyue') {
        return false
      } else {
        return true
      }
    },
    routename() {
      return this.$route.name
    },
    
  },
  watch: {
    routename: function (val, oldval) {
      this.active = val
    }
  },
  methods:{
    reload (){
       this.isRouterAlive = false
       this.$nextTick(function(){
          this.isRouterAlive = true
       })
    },
    onChange(index) {
      switch (index) {
        case 'home':
          this.$router.push({ name: 'home' })
          break
        case 'my':
          this.$router.push({ name: 'my' })
          break
      }
    }
  },

}
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
#app{
  min-height: 100vh;
}
</style>
