<template>
    <div class="head-view">
        <div class="head" :style="{background: $mainColor}">
          <div class="inner">
            <div class="school-logo">
            <!-- <img src="../assets/img/logo.png" class="logo"> -->
            <img src="../assets/img/school.png" class="school">
          </div>
          <!-- <img src="../assets/img/blue60.png" class="blue"> -->
          </div>
        </div>
        <img :src="bannerUrl" class="head-background">
    </div>
  </template>
  <script>
    import { listSysBanner } from "@/api/system/sysBanner"
  export default {
    data(){
      return {
        bannerUrl: require('../assets/img/header.png')
      }
    },
    created() {
      this.getHomeBanner()
    },
    methods: {
    getHomeBanner(){
      listSysBanner().then(e =>{
        const url = e.rows[0].url
        this.bannerUrl =  url ? `${process.env.VUE_APP_BASE_API}${url}` : require('../assets/img/header.png')
      })
    },
      
    }
  }
  </script>
  <style scoped lang="less">
  .head-view{
    width: 100%;
    height: 450px;
    box-shadow: 0 0 12px rgba(0,0,0,0.3);
    .head{
        height: 100px;
        line-height: 100px;
        box-shadow: 0 0 12px rgba(0,0,0,0.3);
        .inner{
          width: 1200px;
          height: 100%;
          margin: 0 auto;
          display: flex;
          align-items: center;
          
        .blue{
          height: 70px;
}
.school-logo{
display: flex;
height: 100%;
align-items: center;
img{
  height: 54px;
}
}
        }
    }
  }
  .head-background{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
  </style>
  