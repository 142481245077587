import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/luqu',
    name: 'luqu',
    component: () => import(/* webpackChunkName: "luqu" */ '../views/luqu.vue')
  },
  {
    path: '/myinfo',
    name: 'myinfo',
    component: () => import(/* webpackChunkName: "myinfo" */ '../views/myinfo.vue')
  },
  {
    path: '/yuyue',
    name: 'yuyue',
    component: () => import(/* webpackChunkName: "yuyue" */ '../views/yuyue.vue')
  },
  {
    path: '/yyuyue',
    name: 'yyuyue',
    component: () => import(/* webpackChunkName: "yyuyue" */ '../views/yyuyue.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "notice" */ '../views/notice.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/xyzy',
    name: 'xyzy',
    component: () => import(/* webpackChunkName: "xyzy" */ '../views/xyzy.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/detail.vue')
  },
  {
    path: '/yydetail',
    name: 'yydetail',
    component: () => import(/* webpackChunkName: "yydetail" */ '../views/yyuyueDetail.vue')
  },
  {
    path: '/my',
    name: 'my',
    component: () => import(/* webpackChunkName: "my" */ '../views/my.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
